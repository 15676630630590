import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  Flex,
  Stack,
  Box,
  Button,
  Text,
  Skeleton,
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'

import {
  OTRIcon,
  RatIcon,
  InsectIcon,
  FlagIcon,
  QualityIcon
} from '../../components/Shared/Icons'

import DocumentsTypes from '../../components/Visits/DocumentsTypes'
import DocumentCard from '../../components/Visits/DocumentCard'
import { useVisit, useMutateVisit } from '../../hooks/visits'
import { useMutateOrt } from '../../hooks/ort'
import { useMutateRegistry } from '../../hooks/rodentsRegistry'
import { useMutateTuv } from '../../hooks/tuv'
import { useMutateControl } from '../../hooks/qualityControl'
import { useMutateReport } from '../../hooks/visitReport'
import { getClientData } from '../../store/slices/visits'

// Pantalla de visita a un cliente
const VisitMenu = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const toast = useToast()
  const { visitId } = useParams()
  const { user } = useSelector((state) => state.auth)
  const [newDocument, setNewDocument] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const { data: visit } = useVisit(visitId)
  const { mutate: mutateOrt } = useMutateOrt('DELETE')
  const { mutate: mutateRegistry } = useMutateRegistry('DELETE')
  const { mutate: mutateTuv } = useMutateTuv('DELETE')
  const { mutate: mutateControl } = useMutateControl('DELETE')
  const { mutate: mutateReport } = useMutateReport('DELETE')
  const { mutate: mutateVisit, isLoading: updateVisitIsLoading } =
    useMutateVisit('UPDATE')

  // Guarda en el estado el cliente y la sucursal
  useEffect(() => {
    if (visit) {
      dispatch(
        getClientData({
          client: visit?.client.company_name || visit?.client.name,
          branch: visit?.branch ? visit?.branch.name : 'Sin Sucursal'
        })
      )
    }
  }, [visit])

  const handleClick = () => {
    history.push('/visitas')
  }

  // Abre lista de documentos que se pueden crear
  const handleNew = () => {
    setNewDocument(true)
  }

  // Terminar visita
  const handleEnd = () => {
    mutateVisit(
      { id: visitId, status: 'Firmada' },
      {
        onSuccess: () => {
          toast({
            title: `Documento terminado`,
            status: 'success',
            isClosable: true
          })
        },
        onError: (error) => {
          if (error.request.response.includes('MISSING_SIGNATURE')) {
            toast({
              title: `Falta firma`,
              status: 'info',
              isClosable: true
            })
          } else if (error.request.response.includes('UNAUTHORIZED')) {
            toast({
              title: `Usuario sin permiso para realizar cierre de documento`,
              status: 'info',
              isClosable: true
            })
          } else {
            toast({
              title: `Error al cerrar documento`,
              status: 'error',
              isClosable: true
            })
          }
        }
      }
    )
  }

  return (
    <Stack spacing={8} py={12} px={6}>
      <Flex spacing={10} align="right" pt="15px">
        <Button
          onClick={handleClick}
          bg="#FFFFFF"
          color="#666587"
          border="1px"
          borderStyle="solid"
          borderRadius="lg"
          borderColor="#F2F1FB"
          leftIcon={<ArrowBackIcon />}
        >
          Regresar
        </Button>
      </Flex>
      {visit ? (
        <>
          <Box w="100%" bg="#F2F1FB" pb={5}>
            <Stack spacing={2} pl={30} mt={2}>
              <Text fontSize="15px" color="#36355F">
                Cliente
              </Text>
              <Text fontSize="30px" fontWeight="bold" color="#36355F">
                {visit?.client.company_name || visit?.client.name}
              </Text>
            </Stack>
            <Flex spacing={10} align="left" pt="15px">
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Sucursal
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {visit?.branch ? visit?.branch.name : 'Sin Sucursal'}
                </Text>
              </Stack>
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Fecha
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {/* {currentDate(visit?.visit.created_at)} */}
                  {visit?.visit.created_at}
                </Text>
              </Stack>
              <Stack spacing={2} pl={30}>
                <Text fontSize="10px" color="#36355F">
                  Área del cliente
                </Text>
                <Text fontSize="17px" fontWeight="bold" color="#36355F">
                  {visit?.visit?.area}
                </Text>
              </Stack>
            </Flex>
          </Box>

          <Tabs isFitted variant="line" defaultIndex={tabIndex}>
            {user.id_role === 5 && visit.visit.status === 'Firmada' && (
              <TabList mb="1em">
                <Tab onClick={() => setTabIndex(0)}>Visita</Tab>
                <Tab onClick={() => setTabIndex(1)}>Certificados</Tab>
              </TabList>
            )}
            <TabPanels>
              <TabPanel>
                {visit.ort.length
                  ? visit.ort.map((data) => (
                      <DocumentCard
                        name={'ORT'}
                        icon={OTRIcon}
                        serial={data.id}
                        rute={`/ort/${data.id}`}
                        mutate={mutateOrt}
                        data={data}
                      />
                    ))
                  : null}
                {visit.registry.length
                  ? visit.registry.map((data) => (
                      <DocumentCard
                        name={'Registro estación control de roedores'}
                        icon={RatIcon}
                        serial={data.id}
                        rute={`/recr/${data.id}`}
                        mutate={mutateRegistry}
                        data={data}
                      />
                    ))
                  : null}
                {visit.tuv.length
                  ? visit.tuv.map((data) => (
                      <DocumentCard
                        name={'TUV para insectos voladores'}
                        icon={InsectIcon}
                        serial={data.id}
                        rute={`/tuv/${data.id}`}
                        mutate={mutateTuv}
                        data={data}
                      />
                    ))
                  : null}
                {visit.control.length
                  ? visit.control.map((data) => (
                      <DocumentCard
                        name={'Control de calidad'}
                        icon={QualityIcon}
                        serial={data.id}
                        rute={`/calidad/${data.id}`}
                        mutate={mutateControl}
                        data={data}
                      />
                    ))
                  : null}
                {visit.report.length
                  ? visit.report.map((data) => (
                      <DocumentCard
                        name={'Reporte de visita'}
                        icon={FlagIcon}
                        serial={data.id}
                        rute={`/reporte_visita/${data.id}`}
                        mutate={mutateReport}
                        data={data}
                      />
                    ))
                  : null}
                {visit.ort.length ||
                visit.registry.length ||
                visit.tuv.length ||
                visit.report.length ||
                visit.control.length ? null : (
                  <Stack spacing={2} pl={30}>
                    <Text fontSize="25px" color="#36355F" fontWeight="bold">
                      Documentos de visita
                    </Text>
                    <Text fontSize="10px" color="#36355F">
                      Aún no hay documentos, empieza por crear uno
                    </Text>
                  </Stack>
                )}
                {newDocument ? (
                  <DocumentsTypes
                    visitId={visitId}
                    user={user.id}
                    setNewDocument={setNewDocument}
                  />
                ) : (
                  <Stack spacing={10} align={'center'}>
                    <Button
                      onClick={() => handleNew()}
                      bg={'white'}
                      color="#59BABA"
                      border="2px"
                      borderStyle="solid"
                      borderRadius="lg"
                      borderColor="#59BABA"
                      // leftIcon={success && <SuccessIcon />}
                    >
                      Crear documento
                    </Button>
                    <Button
                      onClick={() => handleEnd()}
                      bg="#59BABA"
                      color={'white'}
                      border="2px"
                      borderStyle="solid"
                      borderRadius="lg"
                      borderColor="#59BABA"
                      isDisabled={updateVisitIsLoading}
                      isLoading={updateVisitIsLoading}
                    >
                      Terminar documento
                    </Button>
                  </Stack>
                )}
              </TabPanel>
              {user.id_role === 5 && (
                <TabPanel>
                  {visit.ort.every((data) => data.status === 'CREADO') ? (
                    <Stack spacing={2} pl={30}>
                      <Text fontSize="lg" color="#36355F">
                        No se pueden generar certificados hasta que las ORT
                        documentos estén firmadas.
                      </Text>
                    </Stack>
                  ) : (
                    <Stack align="center">
                      <Button
                        onClick={() => handleEnd()}
                        bg="#59BABA"
                        color={'white'}
                        border="2px"
                        borderStyle="solid"
                        borderRadius="lg"
                        borderColor="#59BABA"
                        isDisabled={updateVisitIsLoading}
                        isLoading={updateVisitIsLoading}
                      >
                        Generar certificado
                      </Button>
                    </Stack>
                  )}
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </>
      ) : (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" />
          <Skeleton height="20px" />
        </Stack>
      )}
    </Stack>
  )
}

export default VisitMenu
