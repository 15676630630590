import { useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Button,
  Skeleton,
  IconButton,
  VStack
} from '@chakra-ui/react'
import SignatureCanvas from 'react-signature-canvas'
import { ReloadIcon } from '../Shared/Icons'
import { useMutateUser, useUser } from '../../hooks/users'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ingrese nombre'),
  email: Yup.string().email('Ingrese email válido').required('Ingrese correo')
})

// Perfil de usuario
const ProfileForm = () => {
  const history = useHistory()
  const { userId } = useParams()
  const { data: user } = useUser(userId)
  const { mutate, isLoading: loadingUpdate, reset } = useMutateUser('PROFILE')

  const sigCanvas = useRef({})

  const clear = () => sigCanvas.current.clear()

  const isCanvasEmpty = (canvas) => {
    // Obtiene el contexto de dibujo 2D
    const ctx = canvas.getContext('2d')

    // Verifica el contenido del contexto de dibujo
    const isCanvasEmpty = ctx
      .getImageData(0, 0, canvas.width, canvas.height)
      .data.every((pixel) => pixel === 0)

    return isCanvasEmpty
  }

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      id: userId,
      name: user?.name,
      email: user?.email,
      signature: user?.signature
    },
    onSubmit: (values) => {
      const isEmpty = isCanvasEmpty(sigCanvas.current.getCanvas())

      if (!isEmpty) {
        const signatureUrl = sigCanvas.current
          .getTrimmedCanvas()
          .toDataURL('image/png')

        values.signature = signatureUrl
      }

      mutate(values, {
        onSuccess: () => {
          reset()
          // formik.resetForm()
        },
        onError: (err) => {
          console.log(err)
        }
      })
    }
  })

  const handleBack = () => {
    history.push(`/home`)
  }

  return (
    <Stack spacing={8} mx={'auto'} py={12} px={6} w="full">
      <Box rounded={'lg'} bg={'#FFF'}>
        <Stack spacing={10} align={'flex-start'} w="full" mb={4}>
          <Button
            onClick={handleBack}
            bg="#F2F1FB"
            color="#59BABA"
            border="1px"
            borderStyle="solid"
            borderRadius="lg"
            borderColor="#F2F1FB"
          >
            Regresar
          </Button>
        </Stack>
        {user ? (
          <Stack spacing={4} minW={'xs'}>
            <FormControl
              id="name"
              isRequired
              isInvalid={formik.touched.name && Boolean(formik.errors.name)}
            >
              <FormLabel>Nombre Completo</FormLabel>
              <Input
                borderRadius="30px"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormErrorMessage>
                {formik.touched.name && formik.errors.name}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              id="email"
              isRequired
              isInvalid={formik.touched.email && Boolean(formik.errors.email)}
            >
              <FormLabel>Correo Electrónico</FormLabel>
              <Input
                borderRadius="30px"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
              />
              <FormErrorMessage>
                {formik.touched.email && formik.errors.email}
              </FormErrorMessage>
            </FormControl>
            <VStack w="100%">
              {formik.values.signature ? (
                <>
                  <img src={formik.values.signature} alt="Firma" />
                  <Button
                    onClick={() => formik.setFieldValue('signature', null)}
                    variant="outline"
                    colorScheme="red"
                  >
                    Nueva firma
                  </Button>
                </>
              ) : (
                <>
                  <IconButton
                    aria-label="Clear"
                    icon={<ReloadIcon />}
                    onClick={clear}
                    bg="#FFFFFF"
                  />
                  <Box bg="white" border="1px solid #d1d1d1" borderRadius={8}>
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{
                        width: '400',
                        height: '240%'
                      }}
                    />
                  </Box>
                </>
              )}
            </VStack>

            <Stack spacing={10} align={'center'}>
              <Button
                isLoading={loadingUpdate}
                onClick={formik.handleSubmit}
                bg={'teal.500'}
                color={'white'}
                _hover={{
                  bg: 'teal.600'
                }}
                disabled={loadingUpdate}
                w="20%"
              >
                Editar
              </Button>
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default ProfileForm
